<template>
  <div>
      <vx-card title="Expense Type">
        sip
          <load-table />
      </vx-card>
  </div>
</template>
<script>

import loadTable from "./table.vue";

export default {
  components: {
    loadTable
  },
  data:() => ({
      colorx: "danger",
      tabs: 0,
  })
}
</script>