<style>
.input-readonly>div>.vs-inputx {
	background-color: #e6e6e6;
}

.bg-dark {
	background-color: #e6e6e6 !important;
}
</style>
<template>
	<div class="vx-col w-5/6 ml-auto mr-auto">
		<span>
			<h4 class="mb-5">Create Expense Type</h4>
		</span>

		<div class="vx-row mb-2">


			<div class="vx-col sm:w-full w-full">
				<vs-input name="code" v-model="create.code" v-validate="'required'" class="w-full" :disabled="isEdit"
					label="Expense Type Code" :danger="errors.has('code')" />
				<!-- display: block -->
				<span v-show="errors.has('code')" class="text-danger text-sm help is-danger">
					{{ errors.first("code") }}
				</span>
			</div>

			<div class="vx-col sm:w-full w-full">
				<vs-input name="description" v-model="create.description" v-validate="'required'" class="w-full"
					:disabled="isEdit" label="Description" :danger="errors.has('description')" />
				<!-- display: block -->
				<span v-show="errors.has('description')" class="text-danger text-sm help is-danger">
					{{ errors.first("description") }}
				</span>
			</div>


			<div class="vx-col sm:w-full w-full">
				<label class="vs-input--label">COA</label>
				<multiselect name="coa" v-validate="'required'" :preselectFirst="true" :showNoOptions="false"
					:class="{ 'border-danger': errors.has('coa') }" v-model="create.coa" :options="option.coa"
					:multiple="false" :allow-empty="false" :group-select="false" :max-height="160" :limit="4"
					:internal-search="false" :show-labels="false" @search-change="getOptionCoa" :disabled="isEdit"
					placeholder="Type to search" noOptions="Type to search" track-by="Code" label="Code"
					@input="onChangeCoa(k)">
					<template slot="singleLabel" slot-scope="dt">
						<span class="option__desc">
							<span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
						</span>
					</template>

					<template slot="option" slot-scope="dt">
						<div class="option__desc">
							<span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
						</div>
					</template>
				</multiselect>
				<!-- display: block -->
				<span v-show="errors.has('coa')" class="text-danger text-sm help is-danger">
					{{ errors.first("coa") }}
				</span>
			</div>
		</div>

		<div class="vx-col sm:w-full w-full">
			<div class="vx-col sm:w-full w-full">
				<label class="vs-input--label">Cost Center</label>
				<multiselect :class="{ 'border-danger': errors.has('costCenter') }" :name="'costCenter'"
					v-validate="cost_center_required" v-model="create.costCenter" :options="option.costCenter"
					:multiple="false" :allow-empty="false" :group-select="false" :show-labels="false" :max-height="160"
					:disabled="isEdit" :limit="4" placeholder=" Type to search" track-by="Name" label="Name">
					<template slot="singleLabel" slot-scope="dt">
						<span class="option__desc">
							<span class="option__title">{{ dt.option.Name }}</span>
						</span>
					</template>

					<template slot="option" slot-scope="dt">
						<div class="option__desc">
							<span class="option__title">{{ dt.option.Name }}</span>
						</div>
					</template>
				</multiselect>
				<!-- display: block -->
				<span v-show="errors.has('costCenter')" class="text-danger text-sm help is-danger">
					{{ errors.first("costCenter") }}
				</span>
			</div>
		</div>

		<div class="vx-col sm:w-full w-full">
			<label class="vs-input--label">Is Active ?</label>
			{{ this.create.isActive }}
			<vs-switch v-model="create.isActive" v-bind:class="' test'+ classIsEdit" v-on:click="changeSwitch()">
				<template #on>
					Active
				</template>
				<template #off>
					Non Active
				</template>
			</vs-switch>
		</div>


		<div class="vx-col w-full">
			<br />
			<!-- display: block -->
			<vs-button class="mb-2" v-on:click="handleSubmit">
				<i class="fa fa-save"></i>
				{{ isEdit ? "Update" : "Create" }} Expense Type
			</vs-button>
		</div>
	</div>
</template>

<script>
// import qs from 'qs';
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
// import { Validator } from 'vee-validate';

export default {
	components: {
		Datepicker,
	},
	props: {
		selected: Object,
	},
	created() { },
	data() {
		return this.initialState();
	},
	methods: {
		initialState() {
			let active = false
			// if (this.globalIsEmptyObject(this.selected) == false && this.selected.id !== 0) {
			// 	active = this.selected.status == 1 ? true : false
			// }
			return {
				classIsEdit: "",
				isEdit: false,
				search: "",
				option: {
					invoice: [],
					coa: [],
					costCenter: [],
				},

				inputs: [{
					additional_value: null,
					cost_center: null,
					cost_center_required: false,
					coa: null,
					reason: null,
				}],
				cost_center_required: false,
				create: {
					id: 0,
					code: "",
					description: "",
					coa: null,
					costCenter: null,
					isActive: active,
				},
				customNotif: {
					custom: {
						costCenter: {
							required: "Coct center value is empty",
						},

					},
				},
			};
		},

		getOptionCostCenter() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/master/cost-center", {
					params: {
						order: "name",
						sort: "asc",
					},
				})
				.then((resp) => {
					if (resp.code == 200) {
						this.option.costCenter = resp.data.records;
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Cost Center option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
		changeSwitch() {
			this.classIsEdit = "-";
			if (this.isEdit) {
				if (this.create.isActive) {
					this.classIsEdit = "vs-switch-active";
				} else {
					this.classIsEdit = "";
				}
			}
			console.log(this.create.isActive);
			console.log(this.classIsEdit);
			console.log(this.isEdit);
		},
		getOptionCoa(query) {
			if (query.length <= 2) {
				return;
			}
			this.$vs.loading();
			this.$http
				.get("/api/v1/master/chart-of-account", {
					params: {
						search: query,
						order: "code",
						sort: "asc",
					},
				})
				.then((resp) => {
					if (resp.code == 200) {
						// var _this = this
						this.option.coa = [];
						let coa = [];
						resp.data.records.map((v, i) => {
							coa.push(v.coa);
						});
						this.option.coa = coa;
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Coa option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
		getGeneralForm() {
			// this.getOptionCoa();
			this.getOptionCostCenter();
		},
		handleSubmit() {
			this.$validator.validateAll().then((result) => {
				console.log(result);
				if (result) {
					this.$vs.loading();
					if (this.isActive == false) {
						this.create.status = 0
					} else {
						this.create.status = 1
					}
					let url = "/api/v1/master/expense-type/store";
					if (this.isEdit) {
						url = "/api/v1/master/expense-type/update";
					}
					this.$http
						.post(url, this.create)
						.then((result) => {
							this.$vs.loading.close();
							if (result.code == 200) {
								// this.handleClose()
								this.$vs.notify({
									title: "Success",
									text: "",
									color: "success",
									position: "top-right",
									iconPack: "feather",
									icon: "icon-check",
								});
								this.handleClose();
								// this.uploadAttachmentAfter(0, result.data.AdjustmentFinance.ID);
							} else {
								this.handleClose();
								this.$vs.notify({
									title: "Error",
									text: result.message,
									color: "danger",
									position: "top-right",
									iconPack: "feather",
									icon: "icon-check",
								});
							}
						})
						.catch((e) => { });
				} else {
					this.$vs.notify({
						title: "Error",
						text: this.errors.all().join("\n"),
						color: "danger",
						position: "top-right",
						iconPack: "feather",
						icon: "icon-alert-circle",
					});
				}
			});
		},
		handleClose() {
			window.scrollTo(0, 0);
			this.$emit("closeDetail", true);
		},
		onChangeCoa(index) {
			if (this.inputs[index].coa.Code.slice(0, 1) == "7") {
				// console.log(this.option.costCenter);

				let costCenter = this.option.costCenter.filter(v => v.Code == "OTH")
				// console.log(costCenter, "OTH");

				console.log(costCenter, "costCenter[0]");
				// this.create.costCenter = costCenter[0]
				this.inputs[index].cost_center = costCenter[0]
				this.inputs[index].cost_center_required = true
			} else {
				this.inputs[index].cost_center_required = false
			}
			this.inputs[index].cost_center_required = true
		},
		onEdit() {
			this.create = this.selected;
			this.isEdit = true;

			this.create.coa = {
				Code: this.selected.coa_code,
				Name: this.selected.coa_name,
				ID: this.selected.coa_id,
			};

			this.create.costCenter = {
				Code: this.selected.cost_center_code,
				Name: this.selected.cost_center_name,
				ID: this.selected.cost_center_id,
			};

			this.create.id = this.selected.id;
			// this.$nextTick(() => {
				// this.$validator.validateAll();
				if (this.selected.status == 0) {
					this.create.isActive = false
				} else {
					this.create.isActive = true
				}
			// });

		},
	},
	mounted() {
		this.$validator.localize("en", this.customNotif);
		this.getGeneralForm();
		if (this.globalIsEmptyObject(this.selected) == false && this.selected.id !== 0) {
			this.onEdit()
		}
	},
	computed: {},
	watch: {
		"create.adjustmentValue"() {
			if (this.create.adjustmentValue !== null) {
				let adjustment = this.create.adjustmentValue.toString().replace(/[^.\d]/g, "").toString();
				let controlValue = this.maxAdjustment - adjustment;
				this.create.controlValue = this.formatPrice(parseFloat(controlValue.toString()).toFixed(2));
			} else {
				this.create.controlValue = null;
			}

		},
	},
};
</script>
<style scoped>
.pagination {
	display: flex;

	margin: 0.25rem 0.25rem 0;
}

.pagination button {
	flex-grow: 1;
}

.pagination button:hover {
	cursor: pointer;
}

.border-danger {
	border: 1px solid rgba(var(--vs-danger), 10) !important;

	border-radius: 5px;
}
</style>
